import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ReactMarkdown from 'react-markdown';

import Layout from '../../components/layout';
import ContactDiv from '../../components/contactUs';
import HeadHelmet from '../../components/head';
import { StyledBackground } from '../../components/ui-elements/backgroundImage';

const AboutPage = ({ data }) => {
  const { strapiSsAbout } = data;

  const responsive = { 450: { items: 2 }, 750: { items: 3 }, 1250: { items: 5 } };

  const createImageList = () => {
    let imageDict = strapiSsAbout.ss_about_slider_images;
    let imageGallery = [];
    Object.keys(imageDict).forEach((imageKey) => {
      let image = imageDict[imageKey].gallery_image.childImageSharp.fluid;
      imageGallery.push(image);
    });
    return imageGallery;
  };

  const items = createImageList();

  const galleryItems = (items) => {
    return items.map((image) => {
      return <Img fluid={image} className={'About__sliderImages'} />;
    });
  };

  return (
    <Layout>
      <HeadHelmet data={strapiSsAbout.MetaData} />
      <StyledBackground
        img={strapiSsAbout.hero_image_about_page.childImageSharp.fluid}
        title={strapiSsAbout.title}
        titleWidth="100%"
      />
      <div className={'About__missionDesktopGrid'}>
        <Img
          className={'About__container--aboutSecondImageHelper'}
          fluid={strapiSsAbout.section_1_image_1.childImageSharp.fluid}
        />

        <div className={'SharedEl__textContent--left About__textHelper--1'}>
          <div className={'SharedEl__headingsWidth'}>
            <h3 className={'SharedEl__underline'}>{strapiSsAbout.section_1_title_1}</h3>
          </div>
          <p>{strapiSsAbout.section_1_content_1}</p>
        </div>
        <div className={'SharedEl__textContent--left About__textHelper--2'}>
          <div className={'SharedEl__headingsWidth'}>
            <h3 className={'SharedEl__underline'}>{strapiSsAbout.section_1_title_2}</h3>
          </div>
          <div className={'SharedEl__listStyle About__marginHelper'}>
            <ReactMarkdown>{strapiSsAbout.section_1_content_2}</ReactMarkdown>
          </div>
        </div>
        <div className={'SharedEl__imageHelper About__shared--aboutThirdImageHelper'}>
          <Img
            className={'About__container--aboutThirdImageHelper'}
            fluid={strapiSsAbout.section_1_image_2.childImageSharp.fluid}
          />
        </div>
      </div>
      <div className={'SharedEl__container--greyBG'}>
        <div className={'SharedEl__textContent--center SharedEl__listStyle'}>
          <div className={'SharedEl__headingsWidth'}>
            <h3 className={'SharedEl__underline'}>{strapiSsAbout.section_3_title}</h3>
          </div>
          <div className={'SharedEl__ulHelper'}>
            <ReactMarkdown>{strapiSsAbout.section_3_content}</ReactMarkdown>
          </div>
        </div>
      </div>
      <div className={'SharedEl__textContent--center'}>
        <div className={'SharedEl__headingsWidth'}>
          <h3 className={'SharedEl__underline'}>{strapiSsAbout.section_4_title}</h3>
        </div>
        <div className={'About__special'}>
          <div className={'About__marginTop'}>
            <ReactMarkdown>{strapiSsAbout.section_4_content}</ReactMarkdown>
          </div>

          <div className={'About__imageContainer'}>
            <div className={'About__inlineImage'}>
              <Img
                className={'About__container--aboutFourthImageHelper'}
                fluid={strapiSsAbout.section_4_image_1.childImageSharp.fluid}
              />
            </div>
            <div className={'About__inlineImage'}>
              <Img
                className={'About__container--aboutFifthImageHelper'}
                fluid={strapiSsAbout.section_4_image_2.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
      </div>
      <ContactDiv />
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    strapiSsAbout {
      id
      MetaData {
        id
        title
        metaKeywords
        metaDescription
      }
      title
      hero_image_about_page {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section_1_title_1
      section_1_image_1 {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section_1_content_1
      section_1_title_2
      section_1_image_2 {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section_1_content_2
      section_2_title
      ss_about_slider_images {
        id
        gallery_image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      section_3_title
      section_3_content
      section_4_title
      section_4_content
      section_4_image_1 {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section_4_image_2 {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
